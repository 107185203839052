/*Form Styles*/
.form {
    width: 100%;
    max-width: 500px;
 }
.container{
    padding:30px;
    font-size: 1rem;
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}
h4{
    padding:10px;
    position: fixed;
    top: 0%;
    box-shadow: 1px 2px 8px black;
    z-index: 999;
}
em{
    color: grey;
}

/* Pengiriman Detail Form */
#row-pengiriman{
    width: 55vw;
    box-shadow: 1px 2px 8px black;
}
.logo-container-pengiriman{
    justify-content: left;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 50px;
}
.form-pengiriman{
    width: 100%;
    padding: 20px 50px;
}
.form-control-plaintext{
    outline: none;
}
.label-pengiriman{
    margin-bottom: 0px !important;
    font-weight: bold;
}
.h40{
    min-height: 40px !important;
    height: 40px !important;
}
.logo-pertamina-pengiriman{
    width: 5vw;
}
.col-table{
    border: 1px solid #c1272d;
}

/*Button Styles*/
.btn-container{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.btn {
    width: 20%;
    min-width: 130px;
    cursor: pointer;
    outline: none;
}
a{
    text-decoration: none;
}

/*Upload Image Style*/
.container-image{
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}
.btn-image-text{
    font-size: 1.5rem;
    text-align: center;
}
.btn-image{
    text-align:center;
    margin-top: 30px;
}
.custom-file{
    display: flex;
    justify-content: center;
}
.warning_img{
    height:20px;
}
.form-image{
    padding-left: 5px;
    padding-right: 5px;
}

/*Autocomplete*/
.container-suggestions{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
}
.suggestions {  
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 175px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
    text-align: left;
} 
.suggestions li {
    padding: 0.2rem 0.5rem;
    font-size: 1rem;
}
.suggestion-active,
.suggestions li:hover {
  cursor: pointer;
}

/* Error Message */
.error_info{
    color: red;
    font-size: 1rem;
}

/* Unauthorized */
.unauthorize-container{
    text-align: center;
    height: 365px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 35px;
    background: #e9e9e9;
    box-shadow: 1px 2px 8px black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unauthorize-container h1:hover{
    color: #c1272d;
    cursor: pointer;

}
.unauthorize-container h1{
    transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}
#logo-unauthorized{
    border-right: 5px solid #c1272d;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Toast */
.toast_container{
    position:fixed;
    bottom: 0;
    right: 0;
    margin-right:15px;
    margin-bottom:40px;
    z-index: 1;
}

.success > .toast-header{
    background-color: aquamarine;
}

.error > .toast-header{
    background-color: brown;
}

.warning > .toast-header{
    background-color: yellow;
}

.unknown > .toast-header{
    background-color: blueviolet;
}

/* Ganti Password */
.main-gantiPassword{
    margin-left: 35vh;   
}
.main-gantiPassword-child{
    margin: 22.5vh auto auto auto;
    box-shadow: 1px 2px 8px black;
    max-width: 25vw;
}
.logo-container.gantiPassword{
    display: flex;
    justify-content: center;
    max-width: 25vw;
    margin-left: auto;
    margin-right: auto;
}
.form-LoginForm.gantiPassword, .logo-container.gantiPassword {
    width: 100%;
    max-width: 25vw;
 }
.btn-container.gantiPassword{
    display: flex;
    justify-content: space-between;
}
.btn-secondary{
    float: left;
    background-color: white !important;
    color: #c1272d !important;
}
.form-group-GantiPassword{
    padding: 0px !important;
}
.form-group-GantiPassword>input{
    padding-right: 7vh !important;
    height: calc(1.5vw + 0.75vw + 0vw) !important;
}