.form-LoginForm {
    width: 100%;
    max-width: 900px;
    padding:30px;
    margin-right: auto;
    margin-left: auto;
 }
.box-LoginForm{
    display: flex;
    justify-content: center;     
}

/*Login Form Box Styles*/
.container-LoginForm {
    width: 25%;
    font-size: 1.25rem;   
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* flex-direction: column;
    align-items: center; */
}
.form-control.input-Password{
    padding-right: 7vh !important;
    height: calc(1.5vw + 0.75vw + 0vw) !important;
}

/*Logo Pertamina Style*/
.logo-container{
    display: flex;
    justify-content: center;
    max-width: 900px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.logo-pertamina:not(#logo-sidebar){
    padding:20px;
    width: 15vh;
}

/*Eye Logo Style*/
.eye-logo{
    float: right;
    top: -2vw;
    margin-right: 10px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    font-size: 1.05vw;
}
.eye-logoChild{
    filter: invert(82%) sepia(9%) saturate(102%) hue-rotate(1deg) brightness(87%) contrast(85%);
}
.black{
    filter: invert(0%) sepia(11%) saturate(7482%) hue-rotate(289deg) brightness(98%) contrast(94%);
}

/*Toast Style*/
.container-Toast{
    display: flex;
    justify-content: center;
}

/* Button LoginForm */
#btn-container-login{
    margin-top: 5px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
#btn-loginForm{
    width: 100% !important;
}

/* Spinner Animation */
.spinner-border{
    width: 1.25rem !important;
    height:1.25rem !important;
}

/* Login Admin Nota */
.login-adminNota{
    text-align: center;
    padding: 20px;
}