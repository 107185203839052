/* Table */
.container-table{
    margin-top: 3.5vh;
}
.form-filter{
    padding: 0 5vw 0 5vw;
    margin-bottom: 20px;
}
.form-filter > .title{
    text-align: center;
    font-size:24px;
    font-weight: bold;
}
.form-filter .form-label{
    font-size: 12px;
}
.form-filter button{
    width:100%;
}
.form-btn-filter{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.dataTables_wrapper,
.table_footer{
    max-width: 1080px;   
    font-size: 1em;
    margin-left: auto;
    margin-right: auto;
}
#data_table_wrapper{
    /* height: 500px; */
    text-align: left;
}
#data_table>thead>tr{
    height: 0px !important;
    border: none !important;
}
table.dataTable thead th, table.dataTable thead td{
    border: none;
    background: #c1272d;
    color: white;
}
.dataTables_length{
    padding : 14.25px;
    font-weight: bold;
}

/* Table Row */
table.dataTable tbody th, table.dataTable tbody td {
    padding: 5px 15px;
}
.center.pengiriman{
    font-size: 10px;
    height:100%;
    align-items: center;
}

/* Search Button */
.dataTables_filter>label>input{
    border-radius: 15px;
    border: none;
    outline: none;
    width: 220px;
    height: 30px;
    font-size: 1em;
    padding-left: 10px;
}
.dataTables_filter{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    font-weight: bold;
}

/* Scroll Bar */
::-webkit-scrollbar{
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #e9e9e9;
}
::-webkit-scrollbar-thumb {
    background: #c1272d; 
}

/* Icon */
.icon_container{
    display:flex;
    flex-direction:row;
    justify-content: space-around;
}
.icon{
    width:24px;
    padding:5px;
}
.icon:hover{
    cursor:pointer;
    filter: invert(25%) sepia(96%) saturate(6726%) hue-rotate(351deg) brightness(82%) contrast(79%);
}

/* Button Table */
.btn-table-container{
    text-align: left;
    margin: 0px 60px;
}
#btn-container{
    margin-top:5px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 135px;
}
#btn-tambah{
    width: 20%;
    font-size: 1em;
}


/* Table Footer */
td.pengiriman{
    display: flex;
    justify-content: space-around;
}
.container-pagination{
    display: flex;
    justify-content: center;
    height: 80px;
}
.table_footer{
    width: 100%;
    padding: 10px;
    max-height: 75px;
}

/* Modal */
.modal-body{
    text-align: center;
    word-break: break-word;
}
.modal-footer{
    justify-content: space-evenly !important;
}
ul{
    text-align: left;
}

/* Modal Loading */
.modal-loading-content>div>.modal-content{
    background: transparent;
    outline: none;
    border: none;
    height: 25vw;
}
.modal-loading-body{
    background: transparent; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner-loading{
    width: 5vw !important;
    height: 5vw !important;
    border-color: #c1272d !important;
    border-right-color: transparent !important;
}

/* Nama Supir Pada Tabel Premi */
#nama-supir{
    display: flex;
    justify-content: center;
}

#inv-cust-filter{
    font-size: 10px;
    color:red;
    margin-top:5px;
}

#rekap-filter-container{
    min-height: 105px !important;
}