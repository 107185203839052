body{
    background-color: #e9e9e9 !important;
}
h4{
    background-color: #c1272d;
    color: white;
}

/*Login Form Box Colour*/
.container-LoginForm{
    color: black;
    box-shadow: 2px 2px 8px;
}
.form-LoginForm,
.form-pengiriman{
    background-color: white;
}
.logo-container{
    background-color: white;
    border-bottom: 2px solid #c1272d;
} 
.logo-container-pengiriman{
    background-color: #e9e9e9;
}

/*Input Form Colour*/
.btn-input{
    color: #292929;
}
.btn:not(.btn-secondary){
    background: #c1272d !important;
    color: white;
}

/*Side Bar Colour*/
.sidenav{
    background-color: white;
}
.sidenav a{
    color: black;
}
.active-link{
    background-color: #c1272d;
    color: white !important;
}
.sidenav a:hover{
    color: #c1272d;
}

/*Table Colour*/
table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
    background-color: white;
}
table.dataTable.display tbody tr.odd>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
    background-color:  white;
}
table.dataTable.display tbody tr.even>.sorting_1, table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
    background-color: #e9e9e9;
}
table.dataTable tbody tr {
    background-color: #e9e9e9;
}
.dataTables_length{
    background-color: #c1272d;
    color: white !important;
    box-shadow: 1px 2px 8px black;
}
.dataTables_wrapper .dataTables_scroll{
    box-shadow: 1px 2px 8px black;
    background: #c1272d;
}
.table_footer{
    background-color:white;
    box-shadow: 1px 3px 8px black;
}
.dataTables_filter{
    background-color: #c1272d;
    color: white !important;
}

/*Auto Complete Colour*/
.suggestions{
    border: 1px solid #999;
    background-color: white;
    box-shadow: 1px 2px 5px rgb(43, 41, 41);
}

.suggestion-active,
.suggestions li:hover{
    background-color: #1e90ff;
    color: white;
}

/* Error Colour */
.invalid{
    border: 1px solid red !important;
}