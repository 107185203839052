.sidenav{
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    box-shadow: 2px 2px 8px;
    overflow-x: hidden;
    overflow-y: hidden;
    line-height: 5vh;
}
.sidenav a {
    padding: 6px 6px 6px 20px;
    text-decoration: none;
    font-size: 16px;
    display: block;
}
.sidenav a:hover{
    text-decoration: none;
}
.main{
    margin-left: 300px;
}
#logo-sidebar{
    width: 120px;
    padding: 20px;
}

@media screen and (max-height: 467px){
    .sidenav a{
        font-size: 12px;
    }
    #logo-sidebar{
        width: 80px;
    }
}

/* Button Logout */
.btn-logout-container{
    position: absolute;
    bottom: 0;
    align-items: center;
    max-height: 25vh;
    height: 100%;
    border-top:2px solid #c1272d;
    width: 100%;
}
.button-bottom-container{
    margin-bottom: 3vh;
}

/* Icon Sidebar */
.icon-sidebar{
    margin-right: 20px;
}

/* Copyright style */
.copyright-container{
    padding: 6px 20px 6px 20px;
    font-size: 16px;
    background-color: #e9e9e9;
    color: black;
    word-break: break-all;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8vh;
}

/* Button Refresh */
.button-refresh{
    background: transparent;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.button-refresh>button{
    width: 15vw;
}